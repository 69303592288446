import {GvfService} from "../../../gvf/js/gvf-service";
import {FormField} from "../form-field/form-field";

export class FormFieldInput extends FormField{
    constructor(scope,$root){
        super(scope,$root);
        this.$check = null;
        this.isCheck = null;
        this.isHidden = null;
        this.$input = null;
        this.isTimeInput = null;
        this.tt = null;
    }

    getVal(){
        if(this.isHidden){
            return this.$root.val();
        }else if(this.isCheck){
            return this.$check.get(0).checked;
        }else{
            return this.$root.find(":input").val();
        }
    }

    setVal(val,noEvent){
        if(this.isHidden){
            this.$root.val(val);
        }else if(this.isCheck){
            this.$check.prop("checked",!!val);
        }else{
            this.$root.find(":input").val(val);
        }
        if(!noEvent){
            this.$root.trigger("formField:changeVal");
        }
    }

    ready(){
        super.ready();
        this.$check = this.$root.find("[type=checkbox]");
        this.isCheck = (this.$check.length>0);
        this.isHidden = this.$root.is("input");
        this.$input = this.$root.find(":input");
        this.isTimeInput = (this.$input.attr("type")=="time");

        this.$input.on(
            "change",
            () => {
                this.$root.trigger("formField:changeVal");
            }
        );

        this.$input.keyup(
            (ev) => {
                this.tt = GvfService.delay(500,"fieldinputkeyup").then(
                    () => {
                        this.$root.trigger("formField:checkRestrictions",{"input": $(ev.currentTarget)});
                    }
                );
            }
        ).blur(
            (ev) => {
                if(this.tt){
                    clearTimeout(this.tt);
                    this.tt = null;
                }
                this.$root.trigger("formField:checkRestrictions",{"input": $(ev.currentTarget)});
            }
        );

        if(this.isTimeInput){
            this.$input.clockpicker({
                placement: this.$root.offset().top>250?"top":"bottom",
                align: "left",
                autoclose: true,
                "default": "now"
            });
        }
    }
}

Inputmask.extendAliases(
    {
        numeric: {
            onBeforeMask: function(value){
                if(!isNaN(value)){
                    return parseFloat(value).toFixed(this.opts.digits?this.opts.digits:0).toString().replace(".",this.opts.radixPoint);
                }else{
                    return value;
                }
            },
            onUnMask: function(maskedValue,unmaskedValue){
                if(unmaskedValue || unmaskedValue.length>0){
                    return maskedValue.replace(this.opts.groupSeparator,"").replace(this.opts.radixPoint,".").replace(/[^0-9\\.-]/g,"");
                }else{
                    return "";
                }
            }
        }
    }
);