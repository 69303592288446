import {Component} from "../../../gvf/js/component";
import {GvfService} from "../../../gvf/js/gvf-service";
import {UtilsService} from "../../js/utils-service";
import {ObjectService} from "../../js/object-service";
import {FcmService} from "../../js/fcm-service";
import {GvfUiService} from "../../../gvf/js/gvf-ui-service";
import {GvfConfigService} from "../../../gvf/js/gvf-config-service";

export class BodyComponent extends Component{
    constructor(scope,$root){
        super(scope,$root);
        this.zIndex = 1050;
    }

    closeLogout(url){
        let ldap = window.open(url);
        GvfService.delay(1000).then(() => {
            var $url = GvfConfigService.getValue("apiRootUrl");
            window.location = $url;//+"/?ldap=1";
            ldap.close();
        });
    }

    ready(){
        if(this.$root.data("popup-message")){
            swal(
                {
                    type: "info",
                    title: "",
                    text: this.$root.data("popup-message")
                }
            );
        }

        this.findMember("logout").click(
            (ev) => {
                ev.preventDefault();
                $(ev.currentTarget).addClass("is-loading");
                GvfService.endpoint(
                    "/cp/cp/logout",
                    {}
                ).then(
                    (response) => {
                        if(response.url){
                            this.closeLogout(response.url);
                        }else{
                            window.location="/";
                        }
                    }
                ).catch(
                    (response) => {
                        UtilsService.alertEndpointResponse();
                    }
                ).finally(
                    () => {
                        $(ev.currentTarget).removeClass("is-loading");
                    }
                );
            }
        );



        //modal links functionality
        GvfUiService.ready("a[href*='#modal-']",($element) => {
            $element.click(
                (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    const parts = $(ev.currentTarget).attr("href").split("#modal-");
                    const params = GvfService.parseUrlParams(parts[0].split("?")[1]);
                    const model = parts[1];
                    const objectService = new ObjectService(model.replace(/-/g,"_"));
                    objectService.openDetailModal({
                        objectId: params.id,
                        editMode: params.edit==1,
                        lang: params.lang
                    });
                }
            );
        });
        //tooltips functionality
        GvfUiService.ready("[data-toggle=\"tooltip-help\"]",($element)=>{
            $element.tooltip();
            $element.click(
                (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    let id = $(ev.currentTarget).attr("aria-describedby");
                    if(id){
                        $element.tooltip("toggle");
                    }else{
                        this.$root.find("[data-toggle=\"tooltip-help\"]").tooltip("hide");
                        this.$root.find(".tooltip").remove();
                        this.$root.find("[data-toggle=\"tooltip-help\"]").attr("aria-describedby",null);
                        $element.tooltip("show");
                    }
                }
            );
        });

        this.$root.click(
            ()=>{
                this.$root.find("[data-toggle=\"tooltip-help\"]").tooltip("hide");
                this.$root.find(".tooltip").remove();
                this.$root.find("[data-toggle=\"tooltip-help\"]").attr("aria-describedby",null);
            }
        );

        //Nested modals scroll fix
        $(document).on(
            "hide.bs.modal",
            ".modal",
            () => {
                this.zIndex--;
                if($(".modal:visible").length>0){
                    $(document.body).addClass("modal-open");
                }
                $(".modal-backdrop").css("z-index",this.zIndex-1);
            }
        );
        $(document).on(
            "show.bs.modal",
            ".modal",
            (ev) => {
                this.zIndex++;
                $(ev.target).css("z-index",this.zIndex);
            }
        );
        $(document).on(
            "shown.bs.modal",
            ".modal",
            (ev) => {
                $(".modal-backdrop").css("z-index",this.zIndex-1);
            }
        );

        if(this.$root.data("firebase-vapid")){
            FcmService.init(this.$root.data("firebase-vapid"));
        }
    }
}
