import {Page} from "../js/page";
import {GvfService} from "../../gvf/js/gvf-service";
import {UtilsService} from "../js/utils-service";
import {GvfConfigService} from "../../gvf/js/gvf-config-service";

export class LoginPage extends Page{
    constructor(scope,$root){
        super(scope,$root);
    }

    closeLogout(url){
        let ldap = window.open(url);
        GvfService.delay(50).then(() => {
            var $url = GvfConfigService.getValue("apiRootUrl");
            window.location = $url;//+"/?ldap=1";
            ldap.close();
        });
    }

    ready(){
        this.$loginForm = this.findMember("login-form");
        this.$recoverForm = this.findMember("recover-form");
        this.$confirmLogin = this.findMember("login-confirm");
        this.findMember("recover-link").hide();
        this.findMember("recover-link").click((ev) => {
            ev.preventDefault();
            this.$recoverForm.find("[name=email]").val(this.$loginForm.find("[name=email]").val());
            this.$loginForm.slideUp(() => {
                this.$recoverForm.slideDown();
            });
        });

        this.findMember("login-ldap").click((ev) => {
            ev.preventDefault();
            GvfService.endpoint("/cp/login/validate-email-recover-pass",{value: this.$root.find("[name=email]")[0].value}).then((resp) => {
                if(resp.result){
                    if(!this.$loginForm.data("restrictionsOk")){
                        ev.preventDefault();
                        this.$loginForm.checkRestrictions().then((isOk) => {
                            if(isOk){
                                this.$loginForm.data("restrictionsOk",true);
                                this.$loginForm.submit();
                            }
                        });
                    }
                }else{
                    var $url = GvfConfigService.getValue("apiRootUrl");
                    window.location = $url+"/?ldap=1";
                }
            });
        });
        this.findMember("login-ldap-other-user").click((ev) => {
            ev.preventDefault();
            var $url = GvfConfigService.getValue("apiRootUrl");
            GvfService.endpoint("/cp/login/delete-session-ldap",
                {}
            ).then((response) => {
                if(response.url){
                    this.closeLogout(response.url);
                }else{
                    window.location = $url+"/?ldap=1";
                }
            });
        });

        this.$recoverForm.submit((ev) => {
            ev.preventDefault();
            const $button = this.$recoverForm.find(this.scope+"__recover-button");
            $button.prop("disabled",true);
            GvfService.endpoint("/cp/login/reset-pass-request",
                {data: this.$recoverForm.getValues()}
            ).then((resp) => {
                if(resp.error){
                    UtilsService.alertEndpointResponse(resp.message);
                }else{
                    UtilsService.alertEndpointResponse(resp);
                }
            })
            .catch(UtilsService.alertEndpointResponse)
            .finally(() => {
                $button.prop("disabled",false);
            });
        });

        this.findMember("recover-cancel-button").click((ev) => {
            ev.preventDefault();
            this.$recoverForm.slideUp(() => {
                this.$loginForm.slideDown();
            });
        });

        this.$loginForm.change(
            () => {
                GvfService.endpoint("/cp/login/validate-email-recover-pass",
                    {value: this.$root.find("[name=email]")[0].value}
                ).then((resp) => {
                    if(resp.result){
                        this.findMember("recover-link").show();
                    }else{
                        this.findMember("recover-link").hide();
                    }
                });
            });

        this.findMember("login").click(
            (ev) => {
                ev.preventDefault();
                this.$confirmLogin.submit();
            }
        );

        this.findMember("cancell-confirm").click(
            (ev) => {
                ev.preventDefault();
                GvfService.endpoint("/cp/login/cancell-confirm-login",
                    {}
                ).then((resp) => {
                    window.location = resp.url;
                });
            }
        );

        this.findMember("recover-code-confirm").click(
            (ev) => {
                ev.preventDefault();
                let queryString = window.location.search;
                let urlParams = new URLSearchParams(queryString);
                if(urlParams.get("confirmation") && urlParams.get("k")){
                    GvfService.endpoint("/cp/login/send-new-mail-code-confirm",
                        {confirmation: urlParams.get("confirmation"),k:urlParams.get("k")}
                    ).then((resp) => {
                        UtilsService.alertEndpointResponse(resp);
                        GvfService.delay(1000).then(
                            ()=>{
                                window.location = resp.url;
                            }
                        );

                    });
                }



            }
        );
    }
}

