import {GvfService} from "../../gvf/js/gvf-service";
import {GvfUiService} from "../../gvf/js/gvf-ui-service";

class UtilsService{
    /**
     * Creates an alert based on an endpoint response
     * @param {*} response
     */
    alertEndpointResponse(response){
        if(typeof response == "string"){
            response = {error:true,message:response};
        }

        let isError = true;
        let message = "Error";
        if($.isPlainObject(response)){
            if(response.hasOwnProperty("responseJSON")){ //ajax catched error
                isError = (response.status!=200);
                message = response.statusText;
                response = response.responseJSON;
            }else{
                isError = (response.hasOwnProperty("error") && response.error);
            }
            if(response.hasOwnProperty("message")){
                message = response.message;
            }
        }

        let type = isError?"error":"success";

        if(message.length<300){
            swal(
                {
                    type: type,
                    title: "",
                    text: message
                }
            );
        }else{
            const $modal = $("<div class=\"modal\" tabindex=\"-1\"><div class=\"modal-dialog modal-dialog-scrollable\" role=\"document\"><div class=\"modal-content\"><div class=\"modal-body\">"+message.replace(/\n/,"<br />")+"</div></div></div></div>");
            $("body").append($modal);
            $modal.modal("show");
        }
    }

    /**
     * Creates form modal and returns Promise
     * @param {string} containerEndpoint
     * @param {string} title Title to show
     * @param {string} size Size of modal: xl, lg, etc
     * @returns {Promise<jQuery>}
     */
    createFormModalContainer({containerEndpoint,title,size}){
        return GvfService.endpoint(
            containerEndpoint??"/cp/cp/render-modal-form",
            {title,size}
        ).then(
            (resp)=>{
                const $modal = $(resp.html);
                $("body").append($modal);
                GvfUiService.init($modal);
                return $modal;
            }
        ).catch(this.alertEndpointResponse);
    }

    /**
     * Creates form modal
     * @param {string} formEndpoint Endpoint url to save form
     * @param {object?} formEndpointParams Additional parameters
     * @param {string} saveEndpoint Endpoint url to save form
     * @param {string?} containerEndpoint Endpoint url to get container
     * @param {string?} title Title to show
     * @param {string?} size Size of modal: xl, lg, etc
     * @param {string?} confirmLabel Confirm button label
     * @param {string?} cancelLabel Cancel button label
     * @param {function?} readyCallback
     * @param {function?} confirmCallback
     * @param {object?} objectPopupText
     * @param {string?} fieldName
     * @param {string?} fieldValue
     */
    createFormModal({formEndpoint,formEndpointParams,saveEndpoint,containerEndpoint,title,size,confirmLabel,cancelLabel,readyCallback,confirmCallback,objectPopupText=false,fieldName=false,fieldValue=false}){
        this.createFormModalContainer({containerEndpoint,title,size}).then(
            ($modal)=>{
                GvfService.endpoint(
                    formEndpoint,
                    {params:formEndpointParams??{}}
                ).then(
                    (resp)=>{
                        const $modalForm = $(resp.html);
                        $modal.component().setForm($modalForm);
                        GvfUiService.init($modalForm);
                        $modal.modal('show');
                        if(readyCallback){
                            readyCallback($modal);
                        }
                        $modalForm.submit(
                            (ev)=>{
                                ev.preventDefault();
                                const data = $modalForm.getValues();
                                $modalForm.checkRestrictions().then(
                                    (isOk)=>{
                                        if(isOk){
                                            let fieldConditionalExist = (fieldName && fieldValue && data[fieldName]==fieldValue);
                                            if((objectPopupText.checkResult && fieldConditionalExist) || (objectPopupText && !objectPopupText.checkResult && !fieldConditionalExist)){
                                                swal({
                                                    title: objectPopupText.title,
                                                    text: objectPopupText.subtitleOnly,
                                                    type: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: objectPopupText.confirmButtonColor,
                                                    confirmButtonText: objectPopupText.textConfirm,
                                                    cancelButtonText: "Cancelar",
                                                    closeOnCancel: true,
                                                },(isConfirm) => {
                                                    if(isConfirm){
                                                        $modal.addClass("is-loading");
                                                        GvfService.endpoint(
                                                            saveEndpoint,
                                                            {formData:data}
                                                        ).then(
                                                            (resp)=>{
                                                                $modal.modal("hide");
                                                                $modal.on("hidden.bs.modal",()=>{
                                                                    $modal.remove();
                                                                });

                                                                if(confirmCallback){
                                                                    confirmCallback(resp);
                                                                }else{
                                                                    if(resp.message){
                                                                        this.alertEndpointResponse(resp);
                                                                    }

                                                                    if(resp.url){
                                                                        window.location = resp.url;
                                                                    }
                                                                }
                                                            }
                                                        ).catch(this.alertEndpointResponse).finally(
                                                            ()=>{
                                                                $modal.removeClass("is-loading");
                                                            }
                                                        );
                                                    }
                                                });
                                            }else{
                                                $modal.addClass("is-loading");
                                                GvfService.endpoint(
                                                    saveEndpoint,
                                                    {formData:data}
                                                ).then(
                                                    (resp)=>{
                                                        $modal.modal("hide");
                                                        $modal.on("hidden.bs.modal",()=>{
                                                            $modal.remove();
                                                        });

                                                        if(confirmCallback){
                                                            confirmCallback(resp);
                                                        }else{
                                                            if(resp.message){
                                                                this.alertEndpointResponse(resp);
                                                            }

                                                            if(resp.url){
                                                                window.location = resp.url;
                                                            }
                                                        }
                                                    }
                                                ).catch(this.alertEndpointResponse).finally(
                                                    ()=>{
                                                        $modal.removeClass("is-loading");
                                                    }
                                                );
                                            }

                                        }
                                    }
                                );
                            }
                        );
                    }
                ).catch(this.alertEndpointResponse);
            }
        );
    }

    modelToCamelCase(model){
        return model.charAt(0).toLowerCase()+model.slice(1);
    }

    camelCaseToDash(str){
        return str.replace(/([a-zA-Z])(?=[A-Z])/g,"$1-").toLowerCase();
    }
}

const s_UtilsService = new UtilsService();
export {s_UtilsService as UtilsService};